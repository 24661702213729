@import "../../Base/scss/main.scss";
//
//@media (max-width: 821px) {
//
//
//  .page-container {
//    display: flex;
//    flex-direction: column;
//    overflow-y: scroll;
//    height: 100%;
//
//    .center-content-container {
//      margin: 25px;
//      padding-bottom: 40px;
//
//      .title-text {
//        font-size: 5vw;
//      }
//
//    }
//
//    .side-bar {
//      margin-top: 10px;
//      max-width: 90%;
//    }
//  }
//}
//
//@media (max-width: 1025px) {
//  .description-text {
//    p {
//      margin: 30px 30px 0;
//    }
//  }
//}

.bossfights-logo {
  max-height: 50vh;
  max-width: 50vw;
}

.center-content-container {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: $palette-color-6 #4f4f4f;
}