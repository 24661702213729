@import "./fonts.scss";

// COLOR PALETTE.
$palette-color-1: #888888;
$palette-color-2: #000000;
$palette-color-3: #4f4f4f; // background.
$palette-color-4: #29d5ec;
$palette-color-5: #bf00ff;
$palette-color-6: #22adc0; // title bars and outline.
$palette-color-7: #40484b;

// Header height.
$header-height: 80px;

$body-font: "Montserrat", sans-serif;
$second-font: "Dancing Script", cursive;
$h2-font-size: 1.25rem;
$title-font-size: 2.5rem;
$title-font-weight: 800;
$small-font-size: 0.813rem;
$font-semi-bold: 600;
$z-tooltip: 10;
$z-fixed: 100;
$mobile-max-width: 1150px;
$card-box-shadow: 10px 9px 22px -7px rgba(0, 0, 0, 0.75);

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// FONTS
.my-fonts-young-serif-regular {
  font-family: "Young Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.my-fonts-oswald {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.my-fonts-bree-serif-regular {
  font-family: "Bree Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.my-fonts-new-amsterdam-regular {
  font-family: "New Amsterdam", sans-serif;
  font-weight: 400;
  font-style: normal;
}

// ACTUAL CSS STUFF

body {
  background-color: $palette-color-3;
  height: calc(100% - ($header-height + 5rem));
  position: relative;
  top: calc($header-height);
}

#root {
  display: flex;
  justify-content: center;
}

.main-content {
  display: flex;
  padding: 8px 2px 2px;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.75);
  background-color: $palette-color-6;
  width: calc(100% - 20px);
  height: calc(100% - ($header-height + 2.5rem));
  align-self: center;
  justify-self: center;
}

.depressed-style {
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  margin: 5px;
  height: calc(100% - 10px);
  border-radius: 4px;
  background-color: $palette-color-7;
  box-shadow: inset 0px 1px 20px 8px rgba(0, 0, 0, 0.75);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}


.page-container {
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-around !important;
  overflow-x: hidden;

  .center-content-container {
    border-radius: 10px;
    background-color: white;
    height: 100%;
    //box-shadow: 2px 2px 5px 1px rgb(141, 141, 141);
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-content: center;
    width: 90%;
    margin: 0 20px;
  }

  .side-bar {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 300px;
    background-color: $palette-color-6;
    border-radius: 10px;
    padding: 5px;

    .side-bar-content {
      background-color: $palette-color-6;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 5px 2px;
      display: flex;
      flex-direction: column;

      .side-bar-title {
        display: flex;
        justify-content: center;
        padding: 10px 0 5px;
      }

      .newest-additions {
        display: flex;
        font-size: 20pt;
      }

      .side-bar-items-container {
        background-color: white;
        border-radius: 10px;
        box-shadow: inset 0 0 5px 1px black;
        border: 1px solid black;
        margin: 5px;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: none;
        padding: 10px;
      }
    }
  }

}

.title-text {
  margin: 10px 0 0 0;
  font-size: 4vw;
}

.description-text {
  font-size: 16pt;

  p {
    margin: 30px 70px 0;
  }
}

.text-hyperlink {
  font-weight: bold;
}

.in-page-link {
  a:link {
      color: $palette-color-5;
  }
}

a:link {
  color: black;
}

.no-mobile-support {
  padding: 20px;
  align-content: center;
  text-align: center;
  height: calc(100vh - $header-height - 80px);
  font-size: 1.5rem;

  p {
    margin: 25px 0 25px 0;
  }
}

@media screen and (max-width: 1150px) {
  body {
    //height: calc(100% - ($header-height));
    //top: calc($header-height);
  }

  .main-content {
    display: flex;
    //height: calc(100% - ($header-height + 1.5rem));
  }
}

@media (max-width: 800px) {

  .page-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .center-content-container {
    }

    .side-bar {
      align-self: center;
      margin-top: 25px;
      width: 100%;
      height: 95%;
    }
  }

  .welcome-text {
  }

  .description-text {
    font-size: 4vw;

    p {
      margin: 10px;
    }
  }
}

