@import "../../Base/scss/main.scss";

.page-container {

  .boss-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .boss-content-box {
      @extend .side-bar;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      height: auto;

      .boss-detail-select-container {
        display: flex;
        height: 30px;
        width: 100%;
        justify-items: center;
        align-items: center;
        text-align: center;
        justify-content: space-between;
        padding: 0 50px;
        font-size: 20pt;

        label {
          height: 100%;
          align-content: center;
        }

        select {
          width: 70%;
          height: 100%;
          font-size: 14pt;
        }
      }
    }

    .boss-content-box-no-game-data {
      @extend .boss-content-box;
      height: 100%;
      width: 80vw;
      align-self: center;
      display: flex;
      padding: 40px;

      .we-aint-found-shit {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        .oh-no-text {
          font-size: 10vw;
        }
      }
    }

    .current-selection-options-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
      grid-template-rows: repeat(auto-fill, minmax(300px, 300px));
      justify-content: center; /* Center items horizontally */
      align-content: center; /* Center items vertically */
      grid-gap: 10px;
      padding: 10px;

      .grid-item {
        display: flex;
        padding: 10px;
      }
    }
  }

  .loading-box {
    display: flex;
    flex-direction: column;
    background-color: #29d5ec;
    border-radius: 20%;
    width: 300px;
    height: 400px;
    align-items: center;
    align-self: center;

    .depressed-style {
      border-radius: 20%;
    }
    .text {
      align-self: center;
      margin-top: 50px;
      font-size: 2rem;
    }
  }
  .throbber {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left: 8px solid $palette-color-6;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin: 100px auto;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

}


