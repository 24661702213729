@import "../../../Base/scss/main";

.header {
  margin: 5px;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 0px 1px 5px 2px rgba(0,0,0,0.75);
  position: fixed;
  width: calc(100% - 20px);
  top: 0;
  background-color: $palette-color-6;
  z-index: $z-fixed;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: $header-height;
  padding: 2px 20px 2px 10px;
}

.nav__logo {
  color: $palette-color-2;
  transition: color 0.4s;
  font-size: $title-font-size;
  font-weight: $title-font-weight;
}

.nav__logo__image {
  height: calc($header-height - 4px);
  width: calc($header-height - 4px);
  margin: 0 10px;
}

.nav-logo-and-title-container {
  display: flex;
  align-items: center;
}

.nav__toggle,
.nav__close {
  font-size: 2.5rem;
  color: $palette-color-2;
  cursor: pointer;
}

.nav__cta {
  padding: 10px;
  background-color: white;
  color: $palette-color-6;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.75);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav__cta:hover {
  box-shadow: inset 0px 1px 5px 2px rgba(0,0,0,0.75);
  color: $palette-color-6 !important;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  height: calc(100% - 70px);
}

.socials-container {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 20px;
  background-color: #4f4f4f;

  .facebook-icon {
    color: white;
  }
}

.nav__link {
  padding: 10px;
  color: $palette-color-2;
  font-size: 1.5rem;
  font-weight: 700;
  transition: color 0.4s, background-color 0.4s ease;

  .facebook-icon {
    width: 20px;
    height: 20px;
  }
}

.nav__link:hover {
  color: white;
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

@media screen and (max-width: $mobile-max-width) {
  .nav__menu {
    position: fixed;
    top: 5px;
    right: -100%;
    background-color: rgba($palette-color-3, .3);
    backdrop-filter: blur(15px);
    width: 80%;
    max-width: 400px;
    height: 90%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
    border: 1px $palette-color-2 solid;
    border-radius: 10px 0 0 10px;
    box-shadow: 35px 35px 67px -11px rgba(0,0,0,0.75);
  }

  .nav__logo {
    font-size: 27px;
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 250px;
    font-weight: 900;
  }
}

@media screen and (min-width: $mobile-max-width) {
  .nav {
    //height: calc($header-height + 1rem);
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
}

.show-menu {
  right: 10px;
}

