.cp-regular {
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: normal;
}

.cp-bold {
  font-family: "Courier Prime", monospace;
  font-weight: 700;
  font-style: normal;
}

.cp-regular-italic {
  font-family: "Courier Prime", monospace;
  font-weight: 400;
  font-style: italic;
}

.cp-bold-italic {
  font-family: "Courier Prime", monospace;
  font-weight: 700;
  font-style: italic;
}
