@import "../../../Base/scss/main.scss";

.boss-link-card {
  display: flex;
  flex-direction: column;
  border: 4px solid $palette-color-6;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
  box-shadow: 0 0 5px 2px black;
  position: relative;

  .boss-image {
    width: 100%;
    border-radius: 5px 5px 0 0;
    background-color: $palette-color-6;
  }

  .boss-name {
    display: flex;
    flex-direction: column;
    justify-self: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    font-size: 15pt;
    background-color: $palette-color-6;
  }

  .difficulty {
    font-size: 12pt;
  }

  .game-icon-container {
    top: -4px;
    left: -4px;
    position: absolute;
    width: 45px;
    height: 45px;
    background-color: $palette-color-6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 0;

    .game-icon {
      background-color: white;
      box-shadow: 0 0 2px 2px black;
      width: 30px;
      height: 30px;
    }
  }
}

.boss-link-card:hover {
  //background-color: $palette-color-4;
  box-shadow: 0 0 5px 5px #ffffff;
}