@import "../../../Base/scss/main.scss";

.video-link-card {
  display: flex;
  flex-direction: column;
  border: 4px solid $palette-color-6;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
  box-shadow: 0 0 5px 2px black;
  background-color: $palette-color-6;
  align-content: center;
  align-items: center;
  cursor: pointer;

  .video-image {
    display: flex;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  .video-name {
    display: flex;
    flex-direction: column;
    justify-self: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    font-size: 15pt;
    width: 100%;
    //background-color: $palette-color-3;
    border-radius: 0 0 10px 10px;
    box-shadow: inset 0 0 4px 1px black;
  }
}

.video-link-card:hover {
  //background-color: $palette-color-4;
  box-shadow: 0 0 5px 5px #ffffff;
}