@import "../../../Base/scss/main.scss";

.side-bar {
  //max-width: 400px !important;
  //height: 800px !important;
}

.side-bar-title {
  font-size: 1.3vw;
}

.side-bar-items-container {
  background-color: #4f4f4f !important;
}

.strategy-content-box {
  display: flex;
  flex-direction: column;
  background-color: $palette-color-6;
  border-radius: 10px;
  margin-top: 15px;
  padding: 10px;


  .summary-wrapper{
    display: flex;
    justify-content: space-around;
    height: 90%;
  }

  .title-text {
    background-color: $palette-color-6;
    width: 100%;
    margin: 0;
    font-size: 1vw;
  }

  .contributing-videos-container {
    display: flex;
    width: 400px;
    background-color: #4f4f4f;
    border-radius: 10px;
    line-height: 3;
    box-shadow: inset 0 0 5px 2px black;
  }

  .contributing-videos {

  }

  .summary-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #4f4f4f;
    border-radius: 10px;
    padding: 30px;
    line-height: 3;
    box-shadow: inset 0 0 5px 2px black;
    margin: 3px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: $palette-color-6 #4f4f4f;
  }

  .difficulty-dropdown {
    height: 10%;
    font-weight: 800;
    font-size: 1.2vw;
    //display: flex;
    //background-color: $palette-color-6;
    //border-radius: 10px;
    //box-shadow: inset 0 0 5px 2px black;
    //justify-content: center;
    //align-items: center;
    //padding: 5px 50px;
    //font-size: 1vw;
  }

  label {
    margin: 0 15px;
  }

  select {
    height: 40px;
    width: 200px;
    font-size: .8vw;
  }
}